@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Aleo&display=swap');

$color-theme-yellow: #ffe647;
$color-theme-pale-yellow: #fff4aa;
$color-theme-pink: #fc8ab2;
$color-theme-green: #00855f;
$color-theme-navy: #0d1321;
$color-theme-white: #ffffff;
$color-night-bg: $color-theme-navy;
$color-day-bg: $color-theme-white;
$color-night-text: $color-theme-white;
$color-day-text: $color-theme-navy;
$color-day-link: $color-theme-green;
$color-night-link: $color-theme-pale-yellow;

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

// A general comment that should be
// stripped out in production
body {
  background-color: $color-day-bg;
  color: $color-day-text;
  display: flex;
  flex-direction: column;

  &.has-loaded {
    transition: all 100s;
  }

  &.night {
    background-color: $color-night-bg;
    color: $color-night-text;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Aleo', serif;
}

h1,
h2 {
  position: relative;
  line-height: 1;
  display: inline-block;
  z-index: 1;

  &:after {
    background: linear-gradient(
      145deg,
      $color-theme-yellow 0px,
      $color-theme-yellow 48%,
      $color-theme-pink 52%,
      $color-theme-pink 100%
    );
    content: '';
    display: inline-block;
    height: 5px;
    position: absolute;
    left: 0;
    right: -15px;
    bottom: -8px;
    z-index: -1;
  }
}

h1 {
  font-size: 40px;
  margin: 20px 0 30px 0;

  &:after {
    bottom: -5px;
  }
}

h2 {
  font-size: 24px;
  margin: 32px 0;
}

a {
  color: $color-day-link;

  &:visited {
    color: darken($color-day-link, 5%);
  }

  &:hover,
  &:visited:hover {
    color: darken($color-day-link, 10%);
    text-decoration: none;
  }

  body.night & {
    color: $color-night-link;

    &:visited {
      color: darken($color-night-link, 5%);
    }

    &:hover,
    &:visited:hover {
      color: darken($color-night-link, 10%);
      text-decoration: none;
    }
  }
}

p,
ul {
  line-height: 1.4;
}

li {
  margin-bottom: 10px;
}

main {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
}

img {
  max-width: 100%;
  height: auto;
}

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin-right: 0.25em;
  vertical-align: middle;
  font-size: 1.5em;
  fill: currentColor;
  pointer-events: none;
}

svg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.content {
  position: relative;
  margin-bottom: 20px;
  max-width: 700px;
  padding: 0 20px;

  @media (min-width: 640px) {
    margin-bottom: 40px;
    padding: 0 40px;
  }

  body.night & {
    background: rgba($color-night-bg, 0.5);
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    margin: 10px 0 20px;
  }
  .image--small {
    width: 50%;
    margin: 0px 0 0 20px;
    float: right;
  }
}
footer {
  padding: 10px 20px;
  position: relative;
  z-index: 2;

  body.night & {
    background: rgba($color-night-bg, 0.5);
  }

  @media (min-width: 640px) {
    padding: 20px 40px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 0px 0px 20px;

  @media (min-width: 640px) {
    padding: 10px 20px 30px;
  }
}

.nav {
  display: flex;
  padding: 10px;

  &__link {
    padding: 0 10px;
  }
}

.article-link {
  display: inline-block;
  padding-right: 5px;
  margin: 0 0 10px;
}

.details {
  min-width: 110px;
  position: relative;

  &__summary {
    background: $color-theme-pink;
    color: $color-day-text;
    cursor: pointer;
    padding: 10px 20px;
  }

  &__content {
    background: $color-theme-pale-yellow;
    box-sizing: border-box;
    color: $color-day-text;
    padding: 10px 10px 10px 16px;
    position: absolute;
    width: 100%;
  }

  &__input-wrapper {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    margin-bottom: 4px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }
  }

  input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: $color-theme-white;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: $color-theme-navy;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid $color-theme-navy;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $color-theme-green;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  input[type='radio']:focus {
    outline: 1px solid $color-theme-green;
    outline-offset: 2px;
  }

  a,
  body.night & a {
    color: $color-day-link;

    &:visited {
      color: darken($color-day-link, 5%);
    }

    &:hover,
    &:visited:hover {
      color: darken($color-day-link, 10%);
      text-decoration: none;
    }
  }
}

.weeknotes-link {
  display: inline-block;
  margin-top: 15px;
}

.pagination {
  margin-top: 20px;

  a:first-child {
    margin-right: 20px;
  }
}
strong {
  font-weight: 700;
}
